<template>
  <div v-if="data?.rit" class="flex flex-col gap-2 w-full items-start">
    <p class="font-bold block">
      <TextRitDeel
        short
        :is-retour="data.rit.is_retour"
        :deel-retour="isRetour"
      />
      op
      {{ dateTimeStringLocale(isRetour ? data.rit.retour_datetime : data.rit.vertrek_datetime) }}
    </p>

    <div class="card rounded w-full">
      <LabelRitOpties :suffix="isRetour ? '_retour' : '_heen'" :rit="data.rit" />
      <div class="flex flex-row gap-2 mt-2">
        <ButtonRitOpvolgingStatus
          v-if="store.getters.hasAdminPermission"
          class="w-full max-w-xs"
          :status="(isRetour ? data.rit.opvolging_status_retour : data.rit.opvolging_status_heen)"
          @update:status="$event => handleRitProp(
            data.rit,
            $event,
            `opvolging_status_${isRetour ? 'retour' : 'heen'}`,
          )"
        />
        <ButtonRitCombinatieStatus
          v-if="(isRetour ? data.rit.combinatie_status_retour : data.rit.combinatie_status_heen) > 0"
          :status="(isRetour ? data.rit.combinatie_status_retour : data.rit.combinatie_status_heen)"
          disabled
        />
      </div>
      <LabelPremiumRit v-if="data.rit.kost_luxe_vervoer > 0" />
      <LabelFactuurAangevraagd v-if="data.rit.is_factuur || data.rit.is_factuur_nodig" />

      <GoogleAdressenVisueel class="my-2" :adressen="isRetour ? data.adressen_retour : data.adressen_heen" />
      <GoogleFlightStatus
        class="block mb-2"
        :vlucht="isRetour ? data.rit.retour_vlucht : data.rit.vertrek_vlucht"
        :adressen="isRetour ? data.adressen_retour : data.adressen_heen"
      >Vluchtnummer</GoogleFlightStatus>
      <ButtonAlertPlanningOptie
        v-if="store.getters.hasKantoorPermission"
        :disabled="true"
        :is-retour="data.rit.is_retour"
        :hide-heen="isRetour"
        :hide-retour="!isRetour"
        v-model:heen-memo="data.rit.planning_optie_heen"
        v-model:retour-memo="data.rit.planning_optie_retour"
      />
      <div>
        <BedrijfThemaLogo class="mt-1 mr-auto float-left" :bedrijf="data.rit.bedrijf_thema"></BedrijfThemaLogo>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { useVModel } from '@vueuse/core'
import { dateTimeStringLocale } from '@/functions/formatDate'
import { handleRitProp } from '@/functions/apiHandleRitProp'

const store = useStore()

const props = defineProps({
  info: Object,
  isRetour: Boolean,
})

const emit = defineEmits(['update:info'])
const data = useVModel(props, 'info', emit, { deep: true, passive: true })

const TextRitDeel = defineAsyncComponent(() => import('@/components/UI/Text/RitDeel.vue'))
const LabelRitOpties = defineAsyncComponent(() => import('@/components/UI/Labels/RitOpties.vue'))
const LabelPremiumRit = defineAsyncComponent(() => import('@/components/UI/Labels/PremiumRit.vue'))
const LabelFactuurAangevraagd = defineAsyncComponent(() => import('@/components/UI/Labels/FactuurAangevraagd.vue'))
const GoogleFlightStatus = defineAsyncComponent(() => import('@/components/Google/FlightStatus.vue'))
const GoogleAdressenVisueel = defineAsyncComponent(() => import('@/components/Google/AdressenVisueel.vue'))
const ButtonAlertPlanningOptie = defineAsyncComponent(() => import('@/components/UI/Button/PlanningOptie.vue'))
const ButtonRitCombinatieStatus = defineAsyncComponent(() => import('@/components/UI/Button/RitCombinatieStatus.vue'))
const ButtonRitOpvolgingStatus = defineAsyncComponent(() => import('@/components/UI/Button/RitOpvolgingStatus.vue'))
const BedrijfThemaLogo = defineAsyncComponent(() => import('@/components/UI/Labels/BedrijfThemaLogo.vue'))
</script>

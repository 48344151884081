<template>
  <div>
    <UITableResponsive :items="items" :headers="['Gebruiker', 'Feedback', 'Status']">
      <template v-slot:colgroup>
        <colgroup>
          <col class="w-8" />
          <col class="w-80" />
          <col align="center" class="text-center" />
        </colgroup>
      </template>
      <template v-slot:item-Gebruiker="{ item }">
        <div class="flex flex-col">
          <span>{{ store.getters.chauffeur(item.user_id)?.label }}</span>
          <span class="italic text-gray-500">{{ dateTimeStringLocale(item.creation_date) }}</span>
        </div>
      </template>
      <template v-slot:item-Feedback="{ item }">
        <div class="block whitespace-normal">{{ item.feedback }}</div>
      </template>
      <template v-slot:item-Status="{ item }">
        <span class="btn" :class="{ error: !item.is_behandeld, success: item.is_behandeld }">
          {{ item.is_behandeld ? 'Behandeld' : 'Niet behandeld' }}
        </span>
      </template>
    </UITableResponsive>
  </div>
</template>

<script setup>
import UITableResponsive from '@/components/UI/Table/Responsive'
import { dateTimeStringLocale } from '@/functions/formatDate'
import { useStore } from 'vuex'

const store = useStore()
defineProps({
  items: Array,
})
</script>
